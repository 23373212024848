<template>
  <div class="Index app-box">
    <!-- end 轮播 -->
    <div class="swipeBox">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <div class="imgBox">
            <img :src="GLOBAL.imgURL+ 'home_banner1.png'" alt="111" />
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="imgBox">
            <img :src="GLOBAL.imgURL + 'home_banner.png'" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- end -->
    <div class="merchantBox" @click="goRouter('/CompanyIntroductionTrain')">
      <h4>招商培训</h4>
      <img
        class="merchImg"
        :src="GLOBAL.imgURL + 'home_merchant.png'"
        alt=""
      />
    </div>
    <!-- end 菜单-->
    <div class="menuBox">
      <div class="li" @click="goRouter('/Investor')">
        <img :src="GLOBAL.imgURL + 'home_operation.png'" alt="">
      </div>
      <div class="li lileftMargin" @click="goRouter('/contractOper')">
        <img :src="GLOBAL.imgURL + 'home_operato.png'" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Index",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch('info')
  },
  methods: {},
};
</script>
<style scoped>
.logoImg {
  width: 59px;
  height: 19px;
}
.app-box {
  min-height: 89vh;
  background-color: #efeff4;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.imgBox img {
  width: 100%;
  height: 250px;
}
.merchantBox {
  width: 95%;
  position: absolute;
  left: 50%;
  top: 248px;
  transform: translate(-50%,0);
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -20px;
}
.merchantBox h4{
  margin-bottom: 5px;
  position: relative;
  padding-left: 10px;
}
.merchantBox h4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
  width: 4px;
  height: 16px;
  background: #0e8affff;
  border-radius: 2px;
  margin-right: 3px;
}
.merchImg {
  width: 100%;
  max-height: 100px;
}
.boelBottom{
  margin-bottom: 46px;
}
.swipeBox ::v-deep .van-swipe__indicators{
  bottom: 30px;
}
.menuBox{
  display: flex;
  margin-top: 135px;
  padding: 0 10px;
  box-sizing: border-box;
    padding-bottom: 20px;
}
.menuBox .li{
  flex: 1;
  height: 201px;
}
.menuBox .li img{
  width: 100%;
}
.lileftMargin{
  margin-left: 10px;
}
.merchantBox h4{
  font-size: .45rem;
}
</style>
